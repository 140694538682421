export const routes = [
  {
    path: "/",
    name: "Strona główna",
    component: () => import('../views/Home.vue')
  },
  {
    path: "*",
    component: () => import('../views/Home.vue'),
  },
  {
    path: "/opianach",
    name: "O pianach",
    component: () => import('../views/AboutFoams.vue'),
  },
  {
    path: "/kalkulator",
    component: () => import('../views/Calculator.vue'),
  },
  {
    path: "/oferta",
    name: "Oferta",
    component: () => import('../views/Offer.vue'),
  },
  {
    path: "/galeria",
    name: "Galeria",
    component: () => import('../views/Gallery.vue'),
  },
  {
    path: "/referencje",
    name: "Referencje",
    component: () => import('../views/Reference.vue'),
  },
  {
    path: "/produkty",
    name: "Produkty",
    component: () => import('../views/Products.vue'),
  },
  {
    path: "/produkty/sealtite-pro",
    component: () => import('../views/products/Sealtitepro.vue'),
  },
  {
    path: "/produkty/iq-foam-plus",
    component: () => import('../views/products/Iqfoamplus.vue'),
  },
  {
    path: "/produkty/iq-foam-plus-fr",
    component: () => import('../views/products/Iqfoamplusfr.vue'),
  },
  {
    path: "/kontakt",
    name: "Kontakt",
    component: () => import('../views/Contact.vue'),
  },
];
