<template>
  <div id="app">
    <nav-bar />
    <transition name="fade" mode="out-in">
      <router-view />
    </transition>
    <calculator-widget />
    <foot-bar />
  </div>
</template>

<script>
import NavBar from "./components/NavBar";
import FootBar from "./components/FootBar";
import CalculatorWidget from "./components/CalculatorWidget.vue";

export default {
  components: {
    NavBar,
    FootBar,
    CalculatorWidget,
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600&display=swap");

#app {
  font-family: "Open Sans", sans-serif;
}

@font-face {
  font-family: "fontello";
  src: url("./assets/font/fontello.eot?31712432");
  src: url("./assets/font/fontello.eot?31712432#iefix")
      format("embedded-opentype"),
    url("./assets/font/fontello.woff?31712432") format("woff"),
    url("./assets/font/fontello.ttf?31712432") format("truetype"),
    url("./assets/font/fontello.svg?31712432#fontello") format("svg");
  font-weight: normal;
  font-style: normal;
}

.icon {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.2em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  margin-left: 0.2em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
