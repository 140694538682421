<template>
  <footer>
    <div id="inside">
      <div id="top">
        <img src="../assets/images/logo.png" />
        <div class="info">
          <h1>Dane kontaktowe:</h1>
          <h2>
            <b>Adres: </b>
            Jana Długosza 23, Jaworzno, 43-602
          </h2>
          <h2>
            <b>Województwo: </b>
            Śląskie
          </h2>
          <h2>
            <b>Nr telefonu: </b>
            +48 533 311 117
          </h2>
          <h2>
            <b>E-mail: </b>
            biuro@purtechizolacje.pl
          </h2>
        </div>
        <div class="info">
          <h1>Obserwuj nas:</h1>
          <div class="row">
            <a target="_blank" href="https://www.facebook.com/purtechizolacje/">
              <i class="icon icon-facebook-squared"> &#xf308; </i>
            </a>
          </div>
        </div>
      </div>
      <div id="bottom">COPYRIGHT &copy; 2021 - purtechizolacje.pl</div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "FootBar",
};
</script>

<style scoped>
footer {
  width: 100%;
  background-color: #58585a;
  display: flex;
  flex-direction: column;
  color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

footer div#inside {
  width: 60%;
}

footer div#inside div#top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 40px 0 30px 0;
}

footer div#inside div#top img {
  width: auto;
  height: 60px;
  filter: brightness(0) invert(1);
}

footer div#inside div#top div.info {
  display: flex;
  flex-direction: column;
}

footer div#inside div#top div.info div.row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

footer div#inside div#top div.info div.row a {
  text-decoration: none;
  color: #fff;
}

footer div#inside div#top div.info div.row a {
  text-decoration: none;
  color: #fff;
  font-size: 3rem;
  transition: 0.1s ease;
}

footer div#inside div#top div.info div.row a:hover {
  color: #6ba421;
}

footer div#inside div#top div.info h1 {
  font-size: 1.1rem;
  margin: 0 0 10px 0;
}

footer div#inside div#top div.info h2 {
  font-size: 0.9rem;
  margin: 5px 0 2px 5px;
  color: #ddd;
}

footer div#inside div#top div.info h2 b {
  font-weight: 600;
  color: #fff;
}

footer div#inside div#bottom {
  font-size: 0.7rem;
  padding: 20px 0 20px 0;
}

@media only screen and (max-width: 1280px) {
  footer div#inside {
    width: 100%;
  }

  footer div#inside div#top {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 40px 0 0 0;
  }

  footer div#inside div#top img {
    width: 60%;
    max-width: 300px;
    height: auto;
    filter: brightness(0) invert(1);
  }

  footer div#inside div#top div.info {
    align-items: center;
    margin: 10px 0 10px 0;
  }

  footer div#inside div#top div.info h2 {
    margin: 5px 0 2px 0;
  }

  footer div#inside div#bottom {
    text-align: center;
  }

  footer div#inside div#top div.info div.row {
    flex-direction: column;
  }
}
</style>
