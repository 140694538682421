<template>
  <nav id="navBar">
    <div id="inside">
      <section id="top">
        <img src="../assets/images/logo.png" />
        <div>
          <a href="tel:+48533311117">
            <i class="icon icon-contact" style="margin: 2px 5px 0 0">
              &#xe800;
            </i>
            <h1>+48 533 311 117</h1>
          </a>
          <a href="mailto:biuro@purtechizolacje.pl">
            <i class="icon icon-contact"> &#xe801; </i>
            <h1>Napisz do nas</h1>
          </a>
          <a target="_blank" href="https://www.facebook.com/purtechizolacje/">
            <i class="icon icon-contact"> &#xf308; </i>
          </a>
        </div>
      </section>
      <section id="bottom">
        <router-link
          class="subpage"
          v-for="page in pages"
          :key="page.path"
          :to="page.path"
        >
          <h1 class="subpageName">
            {{ page.name }}
          </h1>
        </router-link>
      </section>
    </div>
    <div id="insideMobile">
      <div id="col1"></div>
      <div id="col2">
        <img src="../assets/images/logo.png" />
      </div>
      <div id="burger" v-on:click="changeBurger">
        <i class="icon" id="icon-burger">
          {{ burgerIcon() }}
        </i>
      </div>
      <div id="burgerMenu">
        <router-link
          class="subpage"
          v-on:click.native="changeBurger"
          v-for="page in pages"
          :key="page.path"
          :to="page.path"
        >
          <h1>
            {{ page.name }}
          </h1>
        </router-link>
        <div id="icons">
          <a href="tel:+48533311117">
            <i class="icon icon-contact"> &#xe800; </i>
          </a>
          <a href="mailto:biuro@purtechizolacje.pl">
            <i class="icon icon-contact"> &#xe801; </i>
          </a>
          <a target="_blank" href="https://www.facebook.com/purtechizolacje/">
            <i class="icon icon-contact"> &#xf308; </i>
          </a>
          <a v-on:click="openCalc">
            <i class="icon icon-calc"> &#xf1ec; </i>
          </a>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "NavBar",
  created() {
    this.$router.options.routes.forEach((route) => {
      if (route.name != null)
        this.pages.push({
          name: route.name,
          path: route.path,
        });
    });

    this.windowSize();
    window.addEventListener("scroll", this.opacityCotroller);
    window.addEventListener("resize", this.windowSize);
  },
  destroyed() {
    window.removeEventListener("scroll", this.opacityCotroller);
    window.removeEventListener("resize", this.windowSize);
  },
  methods: {
    resetScroll: function () {
      window.ScrollTo(0, 0);
    },
    openCalc: function () {
      this.changeBurger();
      this.$router.push("/kalkulator");
    },
    windowSize: function () {
      if (window.innerWidth > 1280) {
        this.mobile = this.burgerOpen = false;

        let el = document.getElementById("burgerMenu");

        if (el != null) el.style.display = "none";
      } else this.mobile = true;
    },
    changeBurger: function () {
      if (this.mobile) {
        if (this.burgerOpen)
          document.getElementById("burgerMenu").style.display = "none";
        else document.getElementById("burgerMenu").style.display = "flex";
        this.burgerOpen = !this.burgerOpen;
      }
    },
    burgerIcon: function () {
      let icon, size;
      let el = document.getElementById("icon-burger");

      if (this.burgerOpen) {
        icon = "&#xe806;";
        size = "35px";
      } else {
        icon = "&#xe802;";
        size = "25px";
      }

      if (el != null) el.style.fontSize = size;

      let parser = new DOMParser();
      let dom = parser.parseFromString(
        "<!doctype html><body>" + icon,
        "text/html"
      );

      return dom.body.textContent;
    },
    opacityCotroller: function () {
      if (!this.mobile) {
        let subpageNameList = document.getElementsByClassName("subpageName");

        if (window.scrollY < this.lastScrollY) {
          document.getElementById("navBar").style.backgroundColor =
            "rgba(240, 240, 240, 1)";
          document.getElementById("navBar").style.height = "120px";

          for (let i = 0; i < subpageNameList.length; i++)
            subpageNameList[i].style.fontSize = "1rem";
        } else {
          document.getElementById("navBar").style.backgroundColor =
            "rgba(240, 240, 240, 0.95)";
          document.getElementById("navBar").style.height = "80px";

          for (let i = 0; i < subpageNameList.length; i++)
            subpageNameList[i].style.fontSize = ".8rem";
        }

        this.lastScrollY = window.scrollY;
      }
    },
  },
  data() {
    return {
      pages: [],
      lastScrollY: 0,
      mobile: false,
      burgerOpen: false,
    };
  },
};
</script>

<style scoped>
nav {
  width: 100vw;
  height: 120px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: #f0f0f0;
  position: fixed;
  top: 0;
  transition: 0.3s background-color ease, 0.3s height ease;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 10000;
}

nav div#inside {
  width: 60%;
  height: 100%;
}

nav div#insideMobile {
  display: none;
  flex-direction: row;
  width: 100%;
}

nav div#inside section#top {
  width: 100%;
  height: 60%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

nav div#inside section#top div {
  min-width: 100px;
  height: 100%;
  display: flex;
  flex-direction: row;
}

nav div#inside section#top div a {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 10px 0 10px;
  text-decoration: none;
  color: #000;
}

nav div#inside section#top div a:hover {
  color: #6ba421;
}

nav div#inside section#top div a i.icon-contact {
  font-size: 1.5rem;
  margin: 0 5px 0 0;
  color: #8dc643;
}

nav div#inside section#top div a h1 {
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  font-size: 1rem;
  transition: 0.1s ease;
}

nav div#inside section#top img {
  height: 100%;
  width: auto;
}

nav div#inside section#bottom {
  width: 100%;
  height: 40%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

nav div#inside section#bottom .subpage {
  height: 100%;
  font-weight: 400;
  padding: 0 20px 0 20px;
  text-decoration: none;
  color: #666;
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: 0.1s ease;
}

nav div#inside section#bottom .router-link-exact-active {
  height: 100%;
  font-weight: 400;
  padding: 0 20px 0 20px;
  text-decoration: none;
  color: #fff;
  background-color: #8dc643;
  display: flex;
  flex-direction: row;
  align-items: center;
}

nav div#inside section#bottom .subpage h1 {
  font-size: 1rem;
  font-weight: 400;
  margin: 0;
  transition: 0.3s font-size ease;
}

nav div#inside section#bottom .subpage:hover {
  color: #fff;
  background-color: #8dc643;
}

nav div#insideMobile div#col1 {
  width: 20%;
}

nav div#insideMobile div#col2 {
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

nav div#insideMobile div#burger {
  width: 20%;
}

nav div#insideMobile div#col2 img {
  height: 100%;
  width: auto;
}

nav div#insideMobile div#burger {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

nav div#insideMobile div#burger i#icon-burger {
  font-size: 25px;
  color: #58585a;
  transition: 0.3s all ease;
}

nav div#insideMobile div#burgerMenu {
  position: fixed;
  top: 80px;
  background-color: #f0f0f0;
  width: 100%;
  display: none;
  flex-direction: column;
  transition: 0.3s display ease;
}

nav div#insideMobile div#burgerMenu .subpage {
  width: 100%;
  padding: 15px 0 15px 0;
  border-bottom: 1px solid #ccc;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
}

nav div#insideMobile div#burgerMenu .router-link-exact-active {
  font-weight: bold;
  color: #444;
}

nav div#insideMobile div#burgerMenu .subpage:last-child {
  border: none;
}

nav div#insideMobile div#burgerMenu .subpage h1 {
  color: #666;
}

nav div#insideMobile div#burgerMenu div#icons {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: 10px 0 10px 0;
}

nav div#insideMobile div#burgerMenu div#icons a {
  text-decoration: none;
}

nav div#insideMobile div#burgerMenu div#icons a i {
  color: #8dc643;
  font-size: 25px;
}

@media only screen and (max-width: 1280px) {
  nav {
    width: 100vw;
    height: 80px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-color: #f0f0f0;
    position: fixed;
    top: 0;
    transition: 0.3s background-color ease, 0.3s height ease;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
  }

  nav div#inside {
    display: none;
  }

  nav div#insideMobile {
    display: flex;
  }
}
</style>
