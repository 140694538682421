<template>
  <section id="calculatorWidget" v-on:click="onWidgetClick" v-if="showWidget">
    <i class="icon icon-calc"> &#xf1ec; </i>
  </section>
</template>

<script>
export default {
  name: "CalculatorWidget",
  methods: {
    onWidgetClick: function () {
      this.$router.push("/kalkulator");
    },
  },
  computed: {
    showWidget: function () {
      if (this.$route.path == "/kalkulator") return false;
      else return true;
    },
  },
};
</script>

<style scoped>
section#calculatorWidget {
  display: flex;
  position: fixed;
  top: 20%;
  right: 0px;
  width: 80px;
  height: 80px;
  background-color: #6ba421;
  border-radius: 2px 0 0 2px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 40px;
  cursor: pointer;
}

@keyframes bounce {
  0% {
    transform: rotate(0deg);
  }
  20% {
    transform: rotate(10deg);
  }
  40% {
    transform: rotate(-10deg);
  }
  60% {
    transform: rotate(10deg);
  }
  80% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

section#calculatorWidget i {
  transition: 0.3s ease;
  animation-name: bounce;
  animation-duration: 4s;
  animation-iteration-count: infinite;
}

@media only screen and (max-width: 1280px) {
  section#calculatorWidget {
    display: none;
  }
}
</style>
